@import '../../scss/settings/variable.scss';

.tour_dashboard {
  flex: 1;
  .row {
    height: 100%;
    .col {
      display: flex;
      flex-direction: column;
      .page_title_container {
        margin-bottom: 20px;

        .page_title {
          color: $grey-dark;
          font-size: 20px;
          font-weight: 500;

          a {
            color: $color-additional;
            &:hover {
              color: $color-additional-hover;
            }
          }
        }

        .page_subtitle {
          color: $grey;
          font-size: 14px;
        }
      }

      .tour_container {
        position: relative;
        flex: 1;

        .track-horizontal {
          display: none;
        }
      }

      .top_buttons {
        margin-bottom: 20px;

        .btn {
          margin-bottom: 0;
          span {
            display: block;
            font-size: 11px;
            line-height: 11px;
            font-style: italic;
          }
        }

        .discard_btn {
          background: $white;
          border: 1px solid $color-additional;
          color: $color-additional;
          margin: 0 15px 0 0;

          &:hover {
            border: 1px solid $color-additional;
            color: $white;
          }

          &:active, &:focus {
            color: $white;
          }

          &:before {
            background-color: $color-additional;
          }
        }

        .save_btn {
          background: $color-additional;
          border: 1px solid $color-additional;
          color: $white;
          margin: 0 15px 0 0;

          &:hover {
            border: 1px solid $color-additional-hover;
            color: $white;
          }

          &:before {
            background-color: $color-additional-hover;
          }
        }
      }

      .select_container {
        margin-top: 10px;
      }

      .inputfield_container {
        margin-top: 16px;
      }

      .pois_container {
        display: flex;
        flex-direction: column;
        height: 100%;
        .card_title_container {
          margin-bottom: 20px;
          .card_title {
            color: $grey-dark;
            font-size: 18px;
            font-weight: 500;
          }

          .btn {
            margin: 0;
          }
        }
        .pois_list_container {
          flex: 1;
          .tour_pois {
            padding-right: 15px;
            width: 100%;
            .poi_line {
              border-left: 1px solid $color-accent-light;
              border-right: 1px solid $color-accent-light;
              margin-left: 10px;
              position: relative;

              span {
                background: $color-accent;
                border: 2px solid $color-accent-light;
                border-radius: 50%;
                color: $white;
                height: 20px;
                left: -10px;
                top: 0;
                position: absolute;
                width: 20px;
                text-align: center;
                line-height: 18px;
                font-size: 11px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
