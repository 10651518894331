@import '../../scss/settings/variable.scss';

.home {
  min-height: calc(100vh - 90px);
  .home_img_container {
    flex: 1;
    .home_img {
      max-height: 500px;
      opacity: 0.6;
    }
  }
}
