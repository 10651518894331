
.plan_list_container {
    height: calc(100% - 60px) !important;
    position: relative;
    flex: 1;
    width: 100%;
  
    .track-horizontal {
      display: none;
    }
  }