@import '../../scss/settings/variable.scss';

.pois_dashboard {
  flex: 1;
  .row {
    height: 100%;
    .col {
      display: flex;
      flex-direction: column;
      .page_title_container {
        margin-bottom: 20px;

        .page_title {
          color: $grey-dark;
          font-size: 20px;
          font-weight: 500;
        }

        .page_subtitle {
          color: $grey;
          font-size: 14px;
        }
      }
    }
  }
}

.business-page__load-redirected {
  .MuiCircularProgress-circle {
    stroke: #4ce1b6!important;
  }
  .redirected-data-loader {
    margin-left: 12px;
  }
  span {
    color: $grey;
    font-size: 16px;
  }
}
