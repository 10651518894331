@import '../../scss/settings/variable.scss';

.new_poi_form {
  .form__form-group {
    margin: 0 0 20px 0 !important;
    .form__form-group-label {
      color: $gray-dark;
      font-size: 14px;
    }
    .material-form__field {
      width: 100%;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: $gray-light !important;
    }
    .MuiInputLabel-outlined {
      font-size: 14px;
      transform: translate(14px, 16px) scale(1);
      &.MuiInputLabel-shrink {
        transform: translate(14px, -3px) scale(0.75);
      }
      &.MuiInputLabel-shrink:not(.Mui-error) {
        color: $color-accent;
      }
    }

    .poi_image_container {
      border: 1px solid $gray-light;
      border-radius: 4px;
      padding: 10px;
      position: relative;

      .poi_image_label {
        color: $color-accent;
        position: absolute;
        -webkit-transform: translate(-8px, -21px) scale(0.75);
        transform: translate(-8px, -21px) scale(0.75);
        background: $white;
        z-index: 1;
        padding: 0 5px;
        font-size: 14px;
      }

      .poi_image {
        height: auto;
        max-height: 300px;
        max-width: 300px;
        width: auto;
      }
    }

    .autocomplete-dropdown-container {
      background: $gray-lighter;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
      left: 2px;
      position: absolute;
      right: 2px;
      top: 40px;
      z-index: 1000;

      .dropdown_row {
        border-bottom: 1px solid $gray-light;
        cursor: pointer;
        padding: 5px 10px;

        &.suggestion-item--active {
          background: $white;
        }

        &:hover {
          background: $white;
        }
      }
    }

    .address_container {
      color: $color-additional;
      width: 100%;

      .coords_checkbox {
        .MuiCheckbox-root {
          .MuiSvgIcon-root {
            fill: $color-additional;
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .business_checkbox {
      .MuiCheckbox-root {
        .MuiSvgIcon-root {
          fill: $color-additional;
          width: 16px;
          height: 16px;
        }
      }
    }

    .coords_form_group {
      margin: 0 0 10px 0 !important;
    }

    .map_area {
      margin: 10px 0 0 0 !important;
      width: 100%;
      height: 80%;
    }
  }

  .form__button-toolbar {
    width: 100%;
    button {
      width: auto;
    }
  }
}
