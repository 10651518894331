@import '../../scss/settings/variable.scss';

.form__form-group{
    .autocomplete-form-field {
        align-items: center;
        justify-content: space-between;

        .MuiCircularProgress-root {
            margin-left: 12px;
            .MuiCircularProgress-circle {
                stroke: #4ce1b6!important;
            }
        }

        
    }

    .MuiAutocomplete-root {
        width: 100%!important;
        // flex: 0 0 80%;
        // margin-right: 12px;
    }

    
    .autocomplete-loader {
        margin-top: 16px;
    }
}