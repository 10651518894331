@import '../../scss/settings/variable.scss';

.user_container {
  margin: 0 15px 20px 0;
  position: relative;

  .user_img {
    flex-shrink: 0;
    border-radius: 4px 0 0 4px;
    height: 108px;
    overflow: hidden;
    position: relative;
    width: 108px;

    img {
      position: absolute;
      height: 108px;
      min-width: 108px;
      width: auto;
    }
  }

  .user {
    background: $white;
    border-radius: 0 4px 4px 0;
    flex: 1;
    flex-basis: 0px;
    color: $grey;
    font-size: 14px;
    min-height: 80px;
    width: 0;
    padding: 10px 20px 10px 10px;

    .user_username {
      color: $grey-dark;
      flex: 1;
      font-size: 14px;
      font-weight: 500;
    }

    .user_email, .user_role {
      flex: 1;
      font-size: 12px;
    }

    .user_email {
      color: $grey;
      font-weight: 400;
    }

    .user_role {
      color: $color-accent;
      font-weight: 600;
    }

    .text_ellipsis {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .duration {
      color: $color-additional;
      font-weight: bold;
    }
  }

  .vertical_buttons {
    min-height: 80px;
    width: 30px;
    position: absolute;
    right: -15px;
    .btn {
      background: $white;
      border: 3px solid $gray-lighter;
      border-radius: 50%;
      height: 30px;
      margin-left: 10px;
      min-width: 30px;
      padding: 0px;
      margin: 2px 0px;

      &:hover {
        border: 3px solid $gray-lighter;
      }

      &:before {
        background-color: $gray-lighter;
      }

      svg {
        fill: $color-additional-light;
        height: 16px;
        margin: 0;
        width: 16px;
      }
      &.remove_btn {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}