@import '../../scss/settings/variable.scss';

.select_container {
  &.full_width {
    width: 100%;
    .select_field {
      width: 100%;
    }
  }

  &.no_bg {
    .select_field {
      .select_form {
        .select_placeholder {
          background: $gray-lighter;
        }
      }
    }
  }

  .select_label {
    color: $grey-dark;
    margin-right: 5px;
  }
  .select_field {
    .select_form {
      position: relative;
      width: 100%;

      .select_placeholder {
        color: $color-accent;
        position: absolute;
        -webkit-transform: translate(-5px, -8px) scale(0.75);
        transform: translate(-5px, -8px) scale(0.75);
        background: $white;
        z-index: 1;
        padding: 0 5px;
        font-size: 14px;
      }
      .MuiInputBase-root {
        &.Mui-disabled {
          color: rgba(0, 0, 0, 0.38);
          svg {
            display: none;
          }
        }

        .MuiSelect-select {
          &:focus {
            background: none;
          }
        }
      }
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $gray-light;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: $gray-light;
      }
      .MuiSelect-select {
        @include themify($themes) {
          color: themed('colorText');
        }
        font-size: 14px;
        padding: 13.5px 34px 12.5px 14px;
      }
    }
  }
}
